const appConfig = {
    apiPrefix: process.env.REACT_APP_API_PREFIX,
    // apiPrefixExt: 'http://localhost:3000/connect',
    // apiPrefix: 'http://sigra.arquivocontabil.com.br:3000',
    apiPrefixExt: process.env.REACT_APP_API_PREFIX_EXT,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
