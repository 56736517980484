import classNames from 'classnames'
import { Spinner } from 'components/ui'
import PropTypes from 'prop-types'

const DefaultLoading = (props) => {
    const {
        loading,
        children,
        spinnerClass,
        spinnerSize,
        enableTheme,
        className,
        asElement: Component,
        customLoader,
        label,
    } = props

    return loading ? (
        <Component
            className={classNames(
                !customLoader && 'flex items-center justify-center h-full',
                className
            )}
        >
            {customLoader ? (
                <>{customLoader}</>
            ) : (
                <div className='flex flex-col items-center justify-center'>
                    <Spinner className={spinnerClass} size={spinnerSize ? spinnerSize : 40} enableTheme={enableTheme ? false : true} />
                    {label &&
                        <p className="text-center text-gray-600 dark:text-gray-300 mt-2">
                            {label}
                        </p>
                    }
                </div>

            )}
        </Component>
    ) : (
        <>{children}</>
    )
}

const CoveredLoading = (props) => {
    const {
        loading,
        children,
        spinnerClass,
        className,
        asElement: Component,
        customLoader,
    } = props

    return (
        <Component className={classNames(loading ? 'relative' : '', className)}>
            {children}
            {loading && (
                <div className="w-full h-full bg-white dark:bg-gray-800 dark:bg-opacity-60 bg-opacity-50 absolute inset-0" />
            )}
            {loading && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
                    {customLoader ? (
                        <>{customLoader}</>
                    ) : (
                        <Spinner className={spinnerClass} size={40} />
                    )}
                </div>
            )}
        </Component>
    )
}

const Loading = (props) => {
    switch (props.type) {
        case 'default':
            return <DefaultLoading {...props} />
        case 'cover':
            return <CoveredLoading {...props} />
        default:
            return <DefaultLoading {...props} />
    }
}

Loading.defaultProps = {
    loading: false,
    type: 'default',
    asElement: 'div',
}

Loading.propTypes = {
    loading: PropTypes.bool,
    spinnerClass: PropTypes.string,
    type: PropTypes.oneOf(['default', 'cover']),
    customLoader: PropTypes.node,
}

export default Loading
